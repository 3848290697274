import {useEffect, useState} from "react";
import SwaggerUI from "swagger-ui-react"

import ApiDocAPI from "../../api/ApiDocAPI";
import { useAlert } from "../../hooks/useAlert";
import { Loader } from '../../components/loader/loader'

import "swagger-ui-react/swagger-ui.css"

export const ApiDocPage = props => {
    const [openApiConfig, setOpenApiConfig] = useState(null)
    const [errorAlert] = useAlert()

    useEffect(() => {
        requestDefaultSettings()
    }, [])

    const requestDefaultSettings = async () => {
        try {
            const file = await new ApiDocAPI().get()
            setOpenApiConfig(file.data)
        } catch (error) {
            errorAlert(error)
        }
    }

    console.log(openApiConfig);

    if (!openApiConfig) {
        return <Loader />
    }

    return <SwaggerUI spec={openApiConfig} />

}
