import { BaseAPI } from "./BaseAPI";

class ChallengesAPI extends BaseAPI {
  constructor() {
    super("plan");
  }

  getAllChallenges = async (offset = 0, limit = 20, text) => {
    return await this.service.get("", {
      params: {
        offset,
        limit,
        text,
      },
    });
  };
  updateChallenge = async (id, challenge) => {
    return await this.service.put(`/${id}`, challenge);
  };

  getChallengeById = async (id) => {
    return await this.service.get(`/${id}/view`);
  };

  createChallenge = async (challenge) => {
    return await this.service.post("", challenge);
  };

  deleteChallenge = async (id) => {
    return await this.service.delete(`/${id}`);
  };

  updateThumbnail = async (id, formData) => {
    return await this.service.put(`/${id}/set-pictures`, formData, {
      headers: {
        "Content-Type": "Content-Type: multipart/form-data; boundary=image",
      },
    });
  };
}

export default ChallengesAPI;
