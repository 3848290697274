import styles from "./input.module.scss";

export const Input = ({ className, type, ...rest }) => {
  return type === "checkbox" ? (
    <label className={styles.checkbox}>
      <input type={type} {...rest} />
      <span className={styles.checkbox__mark} />
    </label>
  ) : (
    <input type={type} {...rest} className={`${className} ${styles.input}`} />
  );
};
