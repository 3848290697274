import {BaseAPI} from "./BaseAPI";

class ApiDocAPI extends BaseAPI {
    constructor() {
        super('docs')
    }

    get = (name) => {
        return this.service.get()
    }
}

export default ApiDocAPI