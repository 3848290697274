import {useState} from "react";
import styles from "./authorization.module.scss";
import PasswordAPI from "../../api/PasswordAPI";
import {useNavigate} from "react-router-dom";
import {Input} from "../../components/input/Input";
import {Button} from "../../components/button/Button";
import {useDispatch} from "react-redux";
import {useAlert} from "../../hooks/useAlert";

export const PasswordRestoreForm = props => {
    const dispatch = useDispatch()

    const [step, setStep] = useState(1)

    const [email, setEmail] = useState("")

    const [passwordRestoreId, setPasswordRestoreId] = useState(null)

    const [restoreCode, setRestoreCode] = useState('')

    const [newPassword, setNewPassword] = useState('')

    const navigate = useNavigate()

    const [successAlert, errorAlert] = useAlert()

    const emailHandler = ({target}) => {
        setEmail(target.value)
    }

    const restoreCodeHandler = ({target}) => {
        setRestoreCode(target.value)
    }

    const newPasswordHandler = (({target}) => {
        setNewPassword(target.value)
    })

    const formSubmitHandler = (event) => {
        event.preventDefault();

        switch (step) {
            case 1:
                new PasswordAPI().sendForgotPasswordRequest(email).then(({data}) => {
                    successAlert('Restore code has been sent to your E-Mail')
                    setPasswordRestoreId(data.id)
                    setStep(2)
                }).catch(errorAlert)
                break;
            case 2:
                new PasswordAPI().sendCheckCodeRequest(restoreCode, passwordRestoreId).then(({data}) => {
                    successAlert('Restore code has been accepted')
                    setStep(3)
                }).catch(errorAlert)
                break;
            case 3:
                new PasswordAPI().sendResetPasswordRequest(passwordRestoreId, restoreCode, newPassword).then(({data}) => {
                    successAlert('Password has been changed')
                    navigate('/auth', {replace: true})
                }).catch(errorAlert)
        }
    }

    const firstStep = (
        <section className={styles.wrapper__fieldset}>
            <Input placeholder={'Email'} type={'email'} value={email} onChange={emailHandler}/>
            <Button title={'Send restore code'}/>
        </section>
    )

    const secondStep = (
        <section className={styles.wrapper__fieldset}>
            <Input type={'code'} maxLength={6} value={restoreCode} onChange={restoreCodeHandler}/>
            <Button title={'Submit restore code'}/>
        </section>
    )

    const thirdStep = (
        <section className={styles.wrapper__fieldset}>
            <Input type={'password'} value={newPassword} onChange={newPasswordHandler}/>
            <Button title={'Reset password'}/>
        </section>
    )

    return (
        <form className={styles.wrapper} onSubmit={formSubmitHandler}>
            <span className={styles.wrapper_logo}>Formulaic</span>
            {step === 1 ? firstStep : step === 2 ? secondStep : thirdStep}
        </form>
    )
}