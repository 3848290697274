import styles from "./authorization.module.scss";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {authorizeUser} from "../../store/operations/profile-operations";
import {Input} from "../../components/input/Input";
import {Button} from "../../components/button/Button";
import {useAlert} from "../../hooks/useAlert";

export const AuthorizationForm = props => {
    const [credentials, setCredentials] = useState({email: '', password: ''})
    const [successAlert, errorAlert] = useAlert()

    const dispatch = useDispatch()

    const navigate = useNavigate()
    const credentialsHandler = (event) => {
        if (event.target.value.match(/^\S*$/)) {
            setCredentials({
                ...credentials,
                [event.target.name]: event.target.value,
            })
        }
    }

    const submitHandler = event => {
        event.preventDefault();

        dispatch(authorizeUser(credentials, () => navigate('/dashboard/users', {replace: true}), errorAlert))

        return false
    }

    return (
        <form onSubmit={submitHandler} className={styles.wrapper}>
            <span className={styles.wrapper_logo}>Formulaic</span>
            
            <fieldset className={`${styles.wrapper__fieldset} ${styles.wrapper__fieldset__inputs}`}>
                <Input onChange={credentialsHandler} placeholder={'Email'} value={credentials.email} name={'email'} type={'email'}/>
                <Input onChange={credentialsHandler} placeholder={'Password'} value={credentials.password} name={'password'} type={'password'}/>
            </fieldset>
            <fieldset className={styles.wrapper__fieldset}>
                <Button type={'submit'} title={'SUBMIT'}/>
                <Button className={styles.wrapper__fieldset_forgot_password} onClick={navigate.bind(null, '/restore-password', {replace: true})} title={'Forgot password ?'}/>
            </fieldset>
        </form>
    )
}
