import { useNavigate } from "react-router-dom";

export const WorkoutsListTableRow = ({
  _id,
  title,
  bodyPart,
  style,
  instructor,
  isFitnessTestVideo
}) => {
  const navigate = useNavigate();
  return (
    <tr onClick={navigate.bind(null, `/dashboard/workouts/${_id}`)}>
      <td>{title}</td>
      <td>{Array.isArray(bodyPart) ? bodyPart.join(",") : bodyPart}</td>
      <td>{style}</td>
      <td>{instructor}</td>
      <td style={{color: (isFitnessTestVideo == undefined || isFitnessTestVideo == false) ? 'black' : 'red'}} >{isFitnessTestVideo == undefined ? 'Production': isFitnessTestVideo == false? 'Production' : 'Test'}</td>
    </tr>
  );
};
