import { useEffect, useState } from "react";

import LogsAPI from "../../../api/LogsAPI";
import { Loader } from "../../../components/loader/loader";
import { useAlert } from "../../../hooks/useAlert";

import styles from "./logs.module.scss";

export const LogsPage = () => {
  const [files, setFiles] = useState(null);
  const [, errorAlert] = useAlert();

  useEffect(() => {
    requestDefaultSettings();
  }, []);

  const requestDefaultSettings = () => {
    new LogsAPI()
      .list()
      .then(({ data }) => {
        setFiles(data);
      })
      .catch(errorAlert);
  };

  const onFileClickHandler = (name) => () => {
    new LogsAPI()
      .get(name)
      .then(({ data }) => {
        const file = new File([data], "foo.txt", {
          type: "text/plain",
        });
        const url = URL.createObjectURL(file);
        window.open(url, "_blank");
      })
      .catch(errorAlert);
  };

  if (!files) return <Loader />;

  return (
    <div className={styles.files}>
      {files.map((file) => (
        <p onClick={onFileClickHandler(file)}>{file}</p>
      ))}
    </div>
  );
};
