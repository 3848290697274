import { Provider } from "react-redux";
import { Routes, Navigate, Route, BrowserRouter } from "react-router-dom";
import { store } from "./store/init/store";
import { AdminPanelLayout } from "./components/admin-panel-layout/AdminPanelLayout";
import { UsersListPage } from "./pages/users/list-page/UsersListPage";
import { AuthorizationForm } from "./pages/auth-page/AuthorizationForm";
import { PasswordRestoreForm } from "./pages/auth-page/PasswordRestoreForm";
import { EquipmentsListPage } from "./pages/equipments/list-page/EquipmentsListPage";
import { WorkoutsListPage } from "./pages/workouts/list-page/WorkoutsListPage";
import { UserPage } from "./pages/users/details-page/UserPage";
import { ChallengesListPage } from "./pages/challenges/list-page/ChallengesListPage";
import { DefaultSettings } from "./pages/default-settings/DefaultSettings";
import { ChallengeCreationPage } from "./pages/challenges/creation-page/ChallengeCreationPage";
import { AlertsWrapper } from "./components/alerts-wrapper/AlertsWrapper";
import { ChallengePage } from "./pages/challenges/details-page/ChallengePage";
import { WorkoutPage } from "./pages/workouts/details-page/WorkoutPage";
import { BillingPage } from "./pages/billing-page/BillingPage";
import { InstructorsListPage } from "./pages/instructors-page/list-page/InscructorsListPage";
import { CreateWorkoutPage } from "./pages/workouts/creation-page/CreateWorkoutPage";
import { LogsPage } from "./pages/logs/list/LogsPage";
import { ApiDocPage } from "./pages/api-doc/ApiDoc";
import { createBrowser } from "airplay-js";
import { useEffect } from "react";
export const Application = (props) => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <AlertsWrapper />

        <Routes>
          <Route path={"dashboard"} element={<AdminPanelLayout />}>
            <Route path={"users"}>
              <Route path={":id"} element={<UserPage />} />
              <Route index element={<UsersListPage />} />
            </Route>

            <Route path={"equipments"}>
              <Route index element={<EquipmentsListPage />} />
            </Route>

            <Route path={"instructors"}>
              <Route index element={<InstructorsListPage />} />
            </Route>

            <Route path={"workouts"}>
              <Route path={":id"} element={<WorkoutPage />} />
              {/*<Route path={'create'} element={<WorkoutCreationPage/>}/>*/}
              <Route path={"create"} element={<CreateWorkoutPage />} />
              <Route index element={<WorkoutsListPage />} />
            </Route>

            <Route path={"challenges"}>
              <Route path={":id"} element={<ChallengePage />} />
              <Route path={"create"} element={<ChallengeCreationPage />} />
              <Route index element={<ChallengesListPage />} />
            </Route>

            <Route path={"settings"}>
              <Route index element={<DefaultSettings />} />
            </Route>

            <Route path={"billing"}>
              <Route index element={<BillingPage />} />
            </Route>

            <Route path={"logs"} element={<LogsPage />} />

            <Route path={"api-doc"} element={<ApiDocPage />} />

            <Route index element={<Navigate to={"users"} />} />
          </Route>

          <Route path={"auth"} element={<AuthorizationForm />} />

          <Route path={"restore-password"} element={<PasswordRestoreForm />} />

          <Route path="*" element={<Navigate to={"dashboard"} />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
};
